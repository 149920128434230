import {useEffect, useRef, useState} from 'react'

import Image from 'next/image'

import Link from 'next/link'

import {useRouter} from 'next/router'

import cn from 'classnames'

import {useTranslation} from 'react-i18next'

import Container from '@/components/atoms/Container'

import Button from '@/components/atoms/Button/Button'

import Label from '@/components/atoms/Label'

import SubtitleWithDot from '@/components/atoms/SubtitleWithDot'

import {formatDate} from '@/functions/utils'

import getAllPosts from '@/functions/wordpress/postTypes/getAllPosts'

import Search from '@/components/molecules/Search'

import Loading from '@/components/molecules/Loading'

import {Post, PostCategory, Posts} from '@/components/types/post'

import {numberOfPostsPerPage} from '@/lib/wordpress/connector'

import {lg} from '@/config'

import useMediaQuery from '@/functions/useMediaQuery'

import useOutsideAlerter from '@/components/atoms/OutsideAlerter/OutsideAlerter'
import styles from './AllPosts.module.scss'

type AllPostsProps = {
  block: {
    title: string
    subtitle: string
    postsCount: number
    categories: PostCategory[]
    posts: Post[]
    allPosts: Post[]
    allPostsToGetYears: Post[]
    pagination: any
  }
}

export default function AllPosts({block}: AllPostsProps) {
  const {
    title,
    subtitle,
    posts,
    categories,
    postsCount,
    pagination,

    allPostsToGetYears
  } = block

  const {t} = useTranslation('blog')

  const router = useRouter()

  const [showMore, setShowMore] = useState(false)

  const [currentPostsCount, setCurrentPostsCount] = useState(postsCount)

  const [categoriesState, setCategoriesState] = useState<PostCategory[]>([])

  const [year, setYear] = useState('')

  const [searchValue, setSearchValue] = useState('')

  const [currentPosts, setCurrentPosts] = useState(posts)

  const [currentPagination, setCurrentPagination] = useState(pagination)

  const [currentCategory, setCurrentCategory] = useState('')

  const [variables, setVariables] = useState({})

  const [loading, setLoading] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)

  const years = allPostsToGetYears.map((post) =>
    new Date(post.date).getFullYear()
  )

  const isLg = useMediaQuery(lg)

  const ref = useRef(null)

  useOutsideAlerter({ref, isOpen, setIsOpen, isOverlay: false})

  const uniqueYears = years
    .filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    .map((year, index) =>
      isLg ? (
        <div onClick={() => setYear(year.toString())} key={index}>
          {year}
        </div>
      ) : (
        <option value={year} key={index}>
          {year}
        </option>
      )
    )

  const fetchPosts = async () => {
    setLoading(true)

    const response = await getAllPosts(variables)

    const data = response?.data as {posts: Posts; allPosts: Posts} | null

    if (data) {
      setCurrentPosts(data.posts.nodes)
      setCurrentPagination(data.posts.pageInfo)
      setCurrentPostsCount(data.allPosts.pageInfo.total)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (Object.keys(variables).length > 0) fetchPosts()
  }, [variables])

  useEffect(() => {
    if (showMore) setCategoriesState(categories)
    else setCategoriesState(categories?.slice(0, 5))
  }, [showMore, categories])

  const isInitialRender = useRef(true)
  useEffect(() => {
    if (!isInitialRender.current) {
      setVariables({
        language: router.locale?.toLocaleUpperCase(),
        year: parseInt(year) || null,
        categoryName: currentCategory || null,
        last: null,
        before: null,
        first: numberOfPostsPerPage,
        after: null,
        search: searchValue || null
      })
      setCurrentPage(1)
    } else {
      isInitialRender.current = false
    }
  }, [year, currentCategory, searchValue])

  const handleNext = () => {
    setVariables({
      language: router.locale?.toLocaleUpperCase(),
      year: parseInt(year) || null,
      categoryName: currentCategory || null,
      last: null,
      before: null,
      first: numberOfPostsPerPage,
      after: currentPagination.endCursor,
      search: searchValue || null
    })
    setCurrentPage(currentPage + 1)
    const element = document.querySelector('#posts') as HTMLElement
    window.scrollTo({
      top: element.offsetTop - 120,
      behavior: 'smooth'
    })
  }

  const handlePrevious = () => {
    setVariables({
      language: router.locale?.toLocaleUpperCase(),
      year: parseInt(year) || null,
      categoryName: currentCategory || null,
      last: numberOfPostsPerPage,
      before: currentPagination.startCursor,
      first: null,
      after: null,
      search: searchValue || null
    })
    setCurrentPage(currentPage - 1)
    const element = document.querySelector('#posts') as HTMLElement
    window.scrollTo({
      top: element.offsetTop - 120,
      behavior: 'smooth'
    })
  }
  return (
    <Container>
      <div className={styles.allPosts}>
        <Label text={subtitle} />
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.wrapper}>
          <div className={styles.sidebar}>
            <div className={styles.totalCount}>{postsCount}</div>
            <div className={styles.filterText}>{t('filterText')}</div>

            <div className={styles.categoriesWrapper}>
              {isLg ? (
                <div
                  ref={ref}
                  className={cn(
                    styles.selectWrapper,
                    styles.select,
                    year.length > 0 ? styles.isSelected : '',
                    isOpen ? styles.isOpen : ''
                  )}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div>{year || t('allYears')}</div>
                  <div
                    className={cn(
                      styles.dropDownWrapper,
                      isOpen ? styles.isOpen : ''
                    )}
                  >
                    <div onClick={() => setYear('')}>{t('allYears')}</div>
                    {uniqueYears}
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    styles.selectWrapper,
                    year.length > 0 ? styles.isSelected : ''
                  )}
                >
                  <select
                    className={cn(
                      styles.select,
                      year.length > 0 ? styles.isSelected : ''
                    )}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value="">{t('allYears')}</option>
                    {uniqueYears}
                  </select>
                </div>
              )}
              {categoriesState?.map(
                (category, index) =>
                  category.count >= 1 && (
                    <div
                      onClick={() =>
                        category.slug === currentCategory
                          ? setCurrentCategory('')
                          : setCurrentCategory(category.slug)
                      }
                      className={cn(
                        styles.tab,
                        category.slug === currentCategory
                          ? styles.isSelected
                          : ''
                      )}
                      key={index}
                    >
                      {category.name}
                    </div>
                  )
              )}
              {categories.length > 5 && (
                <div
                  className={styles.showMore}
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? t('showLess') : t('showMore')}
                </div>
              )}
            </div>
          </div>

          <div id="posts" className={styles.postsWrapper}>
            <Search searchValue={searchValue} setSearchValue={setSearchValue} />
            {loading ? (
              <div className={styles.loading}>
                <Loading />
              </div>
            ) : (
              <>
                <div className={styles.postsWrapper}>
                  {currentPosts?.map((post, index) => (
                    <div className={styles.post} key={index}>
                      {post.featuredImage && (
                        <div className={styles.postImage}>
                          <Image
                            src={post.featuredImage.node.mediaItemUrl}
                            alt={post.featuredImage.node.altText}
                            width={post.featuredImage.node.mediaDetails.width}
                            height={post.featuredImage.node.mediaDetails.height}
                            layout="responsive"
                            objectFit="cover"
                            priority
                          />
                        </div>
                      )}
                      <div>
                        <SubtitleWithDot
                          word1={post.categories.nodes[0].name}
                          word2={formatDate(post.date)}
                          className={styles.postSubtitle}
                        />
                        <Link href={`news/${post.slug}`}>
                          <a className={styles.slideTitle}> {post.title}</a>
                        </Link>
                      </div>
                    </div>
                  ))}
                  {currentPosts.length === 0 && <p>Keine Einträge gefunden</p>}
                </div>

                {currentPosts.length > 0 && (
                  <div>
                    <div className={styles.pagination}>
                      <Button
                        tag="button"
                        disabled={!currentPagination?.hasPreviousPage}
                        text=""
                        type="primary"
                        onClick={() => handlePrevious()}
                      />
                      <div>
                        {currentPage} {`${t('fromText')} `}
                        {Math.ceil(currentPostsCount / numberOfPostsPerPage)}
                      </div>
                      <Button
                        tag="button"
                        disabled={!currentPagination?.hasNextPage}
                        text=""
                        type="primary"
                        onClick={() => handleNext()}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
