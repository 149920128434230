import Image from 'next/image'

import Link from 'next/link'

import styles from './CourseContactPerson.module.scss'

import Container from '@/components/atoms/Container'

import Label from '@/components/atoms/Label'
import {LecturerType} from '@/components/types/lecturer'

type CourseContactPersonProps = {
  block: {
    label: string
    title: string
    team: LecturerType
  }
}

const CourseContactPerson = ({block}: CourseContactPersonProps) => {
  const {
    label,
    title,
    team: {title: lecturerName, lecturer, featuredImage}
  } = block
  return (
    <Container>
      <div className={styles.courseContactPerson}>
        <Label text={label} />
        <div className={styles.title}>{title}</div>
        <div className={styles.imageTextWrapper}>
          {featuredImage?.node && (
            <a className={styles.image}>
              <Image
                src={featuredImage?.node?.mediaItemUrl}
                alt={featuredImage?.node?.altText}
                width={featuredImage?.node?.mediaDetails.width}
                height={featuredImage?.node?.mediaDetails.height}
                layout="responsive"
                priority
              />
            </a>
          )}
          <div className={styles.textWrapper}>
            <div className={styles.name}>{lecturerName}</div>
            <div className={styles.role}>
              <div>{lecturer.role}</div>
              <div>{lecturer.location}</div>
            </div>
            <div className={styles.telephone}>{lecturer.telephone}</div>
            <div className={styles.email}>
              <Link href={`mailto:${lecturer.email}`}>{lecturer.email}</Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CourseContactPerson
